@import '../../scss/theme-bootstrap';

.elc-responsive-modal-mask {
  background-color: $color--black--opacity;
  .elc-product {
    &-notify-me-pop-up-wrapper,
    &-notify-me-confirmation-wrapper {
      top: 50%;
      left: 50%; // used for center position, with translate, no RTL required
      right: auto; // used for keeping the box center aligned, no RTL required 
      transform: translate(-50%, -50%);
      align-self: center;
      height: auto;
      padding: 20px 0 0;
      &:after {
        content: unset;
      }
    }
    &-notify-me-pop-up-close-button {
      background: $color--white;
      align-self: flex-end;
      position: absolute;
      margin: 10px 20px;
      .elc-remove-icon {
        background: $color--black;
      }
    }
    &-notify-me-content {
      padding: 10px 20px;
      display: flex;
      flex-wrap: wrap;
    }
    &-notify-me-title-label,
    &-notify-me-description-label {
      width: 100%;
      text-align: #{$ldirection};
    }
    &-notify-me-title-label {
      @include title-03--bold;
      padding-#{$rdirection}: 20px;
      order: 1;
    }
    &-notify-me-description-label {
      @include body-text;
      order: 2;
    }
    &-notify-me-submit-button,
    &-notify-me-confirmation-continue-button {
      @include cta-primary-filled;
      width: auto;
      margin-#{$ldirection}: auto;
      margin-top: 20px;
      order: 3;
    }
    &-notify-me-form-wrapper {
      margin: 20px 0 0;
      width: auto;
      flex: 1;
      margin-#{$rdirection}: 20px;
      order: 3;
    }
    &-notify-me-notice-wrapper {
      order: 4;
      width: 100%;
      .elc-checkbox {
        margin-top: -8px;
      }
      .elc-product-notify-me-gdpr-notice-label {
        margin-top: 3px;
      }
    }
    &-notify-me-info {
      order: 5;
      width: 100%;
      padding-top: 20px;
      &-button {
        background-color: transparent;
        @include swap_direction(padding, 0 5px 0 0);
        &:hover {
          background-color: transparent;
          & ~ .elc-product-gdpr-popup {
            display: block;
            bottom: 20%;
          }
        }
      }
      &-your-data {
        padding-top: 14px;
      }
    }
    &-gdpr-popup {
      display: none;
      margin: 0 20px 0;
    }
    &-notify-me-gdpr-read-confirmation,
    &-notify-me-gdpr-notice-label,
    &-notify-me-info-your-data,
    &-gdpr-popup-information,
    &-notify-me-confirmation-content-text  {
      @include legal-text;
    }
    &-notify-me-confirmation-title {
      @include body-text--bold;
      font-size: 20px;
    }
    &-notify-me-gdpr-read-confirmation {
      margin-top: 5px;
      color: $color--red;
    }
    &-notify-me-confirmation-wrapper {
      padding: 20px;
    }
    &-notify-me-confirmation-content {
      margin: 0 auto;
      padding: 0;
      max-width: 100%;
    }
    &-notify-me-confirmation-continue-button {
      position: relative;
    }
    &-notify-me-confirmation-close-button {
      max-width: 25px;
      background-color: white;
      text-align: #{$rdirection};
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
      padding-top: 10px;
      padding-#{$rdirection}: 20px;
    }
  }
}